/* eslint-disable jsx-a11y/anchor-has-content */
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import SinNombreVideo from "./Assets/Sin Nombre.mp4";
import TodoListVideo from "./Assets/Todo List.mp4";
import WeatherAppVideo from "./Assets/Weather App.mp4";
import BadMoonVideo from "./Assets/Bad-Moon-video-1.mp4";
import StickyFingersVideo from "./Assets/Sticky Fingers.mp4";

export default function FrontendVideoSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const scrollTo = (id: string) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      const container = document.querySelector(".product-list-container");
      if (container) {
        container.scrollTo({
          left: targetDiv.offsetLeft,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <>
      <div className="black-container">
        <div className="section-container">
          <div className="product-list-container">
            <div className="product-list">
              <div
                className="product-card scroll-container slides fade"
                id="1"
                ref={ref}
              >
                <video
                  src={StickyFingersVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <h5 className="">Sticky Fingers</h5>
                <div className="text">
                  Static Site
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">Framer Motion</span>
                </div>
              </div>
              <div
                className="product-card scroll-container slides fade"
                id="2"
                ref={ref}
              >
                <video
                  src={BadMoonVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <h5 className="">BAD MOON</h5>
                <div className="text">
                  Static Site
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">GSAP</span>
                  <span className="project-tech-stack">Framer Motion</span>
                </div>
              </div>
              <div className="product-card scroll-container slides fade" id="3">
                <video
                  src={SinNombreVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <h5 className="">Sin Nombre</h5>
                <div className="text">
                  Static Site
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">Tailwind CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                </div>
              </div>
              <div className="product-card scroll-container slides fade" id="4">
                <a href="https://arranstrange.github.io/weather-api-app/">
                  <video
                    src={WeatherAppVideo}
                    // autoPlay
                    muted
                    loop
                    playsInline
                    controls={true}
                    className="video"
                  />
                </a>
                <h5>WEATHER APP</h5>
                <div className="text">
                  Weather Web App
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">AXIOS</span>
                </div>
              </div>
              <div className="product-card scroll-container slides fade" id="5">
                <a href="https://arranstrange.github.io/todo-list/">
                  <video
                    src={TodoListVideo}
                    // autoPlay
                    muted
                    loop
                    playsInline
                    controls={true}
                    className="video"
                  />
                </a>
                <h5>TODO LIST App</h5>
                <div className="text">
                  Todo List Web App
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a className="dot" href="#1" onClick={() => scrollTo("1")}></a>
            <a className="dot" href="#2" onClick={() => scrollTo("2")}></a>
            <a className="dot" href="#3" onClick={() => scrollTo("3")}></a>
            <a className="dot" href="#4" onClick={() => scrollTo("4")}></a>
            <a className="dot" href="#5" onClick={() => scrollTo("5")}></a>
          </div>
        </div>
      </div>
    </>
  );
}
